import { FormEvent, SetStateAction, useEffect, useState } from "react";
import ProfileFormSection, {
    ProfileSectionTitle,
} from "../components/profile/ProfileFormSection";
import ProfileFormSectionDomains from "../components/profile/ProfileFormSectionDomains";
import { Box, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ProfileSectionCheck from "../components/profile/ProfileSectionCheck";
import { Link, useNavigate, useParams } from "react-router-dom";
import useData from "../hooks/useApi";
import useMessage from "../hooks/useMessage";
import ConfirmationBox from "../components/common/AlertBoxes/ConfirmationBox";
import { useFormik } from "formik";
import {
    defaultValues,
    FormValuesType,
    generateSchema,
} from "../data/profileData";
import { FormDataType, InputSectionTag } from "../types/profileSettingsTypes";

const ProfileForm = ({
    setUsername,
    disabled,
}: {
    setUsername: React.Dispatch<SetStateAction<string>>;
    disabled: boolean;
}) => {
    const [isOwnerAdmin, setIsOwnerAdmin] = useState<boolean>(false);
    const [isOwnerTechnical, setIsOwnerTechnical] = useState<boolean>(false);
    const [isOwnerBilling, setIsOwnerBilling] = useState<boolean>(false);

    const params = useParams();
    const navigate = useNavigate();
    const { fetchData, postData } = useData();
    const { showError, showSuccess } = useMessage();
    const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
    const [updateAlert, setUpdateAlert] = useState<boolean>(false);

    const [submissionData, setSubmissionData] = useState<any>(null);

    async function getProfileData() {
        try {
            const {
                data,
                success,
            }: {
                data: FormDataType;
                success: boolean;
            } = await fetchData(
                `/users/getUserRegistrantProfile/${params.username}`,
            );
            if (!success) {
                throw new Error("Error fetching data");
            }
            if (!data || !data.adminInfo) {
                formik.resetForm();
                setAdminSameAsOwner(true);
                setTechnicalSameAsOwner(true);
                setBillingSameAsOwner(true);
            } else {
                setFormValue(InputSectionTag.OWNER_INFO, data.ownerInfo);

                // Set Admin Info if available
                if (data.adminInfo) {
                    setAdminSameAsOwner(data.adminInfo.sameAsOwner);
                    setFormValue(InputSectionTag.ADMIN_INFO, data.adminInfo);
                }

                // Set Technical Info if available
                if (data.technicalContact) {
                    setTechnicalSameAsOwner(data.technicalContact.sameAsOwner);
                    setFormValue(
                        InputSectionTag.TECHNICAL_INFO,
                        data.technicalContact,
                    );
                }

                // Set Billing Info if available
                if (data.billingContact) {
                    setBillingSameAsOwner(data.billingContact.sameAsOwner);
                    setFormValue(
                        InputSectionTag.BILLING_INFO,
                        data.billingContact,
                    );
                }

                // Set Nameserver info
                setFormValue(
                    InputSectionTag.NAMESERVERS,
                    data.nameserverSettings,
                );
                setFormValue(
                    "allowDailyEmailReports",
                    data.allowDailyEmailReports,
                );
            }
        } catch (e: any) {
            navigate("/admin/users");
        }
    }

    useEffect(() => {
        if (params && params?.username) {
            setUsername(params.username);
            getProfileData();
        } else {
            navigate("/users");
        }
    }, [params]);

    async function deleteProfileData(username: string) {
        try {
            if (username) {
                await postData("/users/deleteUserRegistrantProfile", {
                    username,
                });
                await getProfileData();
                showSuccess("Profile settings successfully deleted");
            } else {
                throw new Error("Username not found");
            }
        } catch (error) {
            showError("Profile deletion failed");
        }
        setDeleteAlert(false);
    }

    async function handleFormSubmit(values: FormValuesType) {
        try {
            const {
                ownerInfo,
                adminSameAsOwner,
                technicalSameAsOwner,
                billingSameAsOwner,
                nameservers,
                allowDailyEmailReports,
            } = values;
            const formData: FormDataType = {
                ownerInfo: ownerInfo,
                adminInfo: adminSameAsOwner
                    ? { ...ownerInfo, sameAsOwner: adminSameAsOwner }
                    : { ...values.adminInfo, sameAsOwner: adminSameAsOwner },
                technicalContact: technicalSameAsOwner
                    ? { ...ownerInfo, sameAsOwner: technicalSameAsOwner }
                    : {
                          ...values.technicalInfo,
                          sameAsOwner: technicalSameAsOwner,
                      },
                billingContact: billingSameAsOwner
                    ? { ...ownerInfo, sameAsOwner: billingSameAsOwner }
                    : {
                          ...values.billingInfo,
                          sameAsOwner: billingSameAsOwner,
                      },
                nameserverSettings: {
                    nameserver1: nameservers.nameserver1,
                    nameserver2: nameservers.nameserver2,
                },
                allowDailyEmailReports: allowDailyEmailReports,
            };

            setSubmissionData(formData);
            setUpdateAlert(true);
        } catch (error: any) {
            return;
        }
    }

    const formik = useFormik({
        initialValues: defaultValues,
        validationSchema: generateSchema({
            adminSameAsOwner: isOwnerAdmin,
            billingSameAsOwner: isOwnerBilling,
            technicalSameAsOwner: isOwnerTechnical,
        }),
        onSubmit: handleFormSubmit,
    });

    const setAdminSameAsOwner = (isSame: boolean) => {
        setFormValue("adminSameAsOwner", isSame);
        setIsOwnerAdmin(isSame);
    };

    const setTechnicalSameAsOwner = (isSame: boolean) => {
        setFormValue("technicalSameAsOwner", isSame);
        setIsOwnerTechnical(isSame);
    };

    const setBillingSameAsOwner = (isSame: boolean) => {
        setFormValue("billingSameAsOwner", isSame);
        setIsOwnerBilling(isSame);
    };

    function setFormValue(field: string, value: any) {
        formik.setFieldValue(field, value);
    }

    async function submitAfterConfirming(username: string) {
        try {
            if (submissionData) {
                const result: any = await postData(
                    "/users/updateUserRegistrantProfile",
                    { data: submissionData, username },
                );
                if (result && result.success) {
                    showSuccess("Profile settings successfully updated");
                    navigate(`/profile/${params.username}`);
                } else {
                    throw new Error("update failed");
                }
            } else {
                throw new Error("update failed");
            }
        } catch (error) {
            console.error(error);
            showError("Profile update failed");
        }
        setUpdateAlert(false);
    }

    // Passed onto the form onSubmit. Handles showing the error message toast if form data is invalid
    const formOnSubmit = (e: FormEvent<HTMLFormElement>) => {
        if (!formik.isValid) {
            showError("Profile update failed");
        }
        formik.handleSubmit(e);
    };

    const getEmailReportsFlag = () => formik.values.allowDailyEmailReports;
    const toggleEmailReportsFlag = () => {
        setFormValue(
            "allowDailyEmailReports",
            !formik.values.allowDailyEmailReports,
        );
    };

    return (
        <form
            style={{
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                flexGrow: 1,
            }}
            onSubmit={formOnSubmit}
        >
            <ConfirmationBox
                open={deleteAlert}
                setOpen={setDeleteAlert}
                title="Confirm removing profile settings"
                subtitle="This action cannot be undone"
                triggerFunction={deleteProfileData}
                triggerValue={params.username}
            />
            <ConfirmationBox
                open={updateAlert}
                setOpen={setUpdateAlert}
                title="Confirm updating profile settings"
                subtitle="This action cannot be undone"
                triggerFunction={submitAfterConfirming}
                triggerValue={params.username}
            />
            <Stack>
                <ProfileFormSection
                    tag={InputSectionTag.OWNER_INFO}
                    disabled={disabled}
                    formik={formik}
                />

                <Box sx={{ mt: 2, mb: 1 }}>
                    <ProfileSectionTitle title="Admin Info" />
                    <ProfileSectionCheck
                        label="Same as Owner"
                        checked={isOwnerAdmin}
                        onChange={setAdminSameAsOwner}
                        disabled={disabled}
                    />
                </Box>
                {!isOwnerAdmin && (
                    <ProfileFormSection
                        tag={InputSectionTag.ADMIN_INFO}
                        disabled={disabled}
                        formik={formik}
                    />
                )}

                <Box sx={{ mt: 2, mb: 1 }}>
                    <ProfileSectionTitle title="Technical Info" />
                    <ProfileSectionCheck
                        label="Same as Owner"
                        checked={isOwnerTechnical}
                        onChange={setTechnicalSameAsOwner}
                        disabled={disabled}
                    />
                </Box>

                {!isOwnerTechnical && (
                    <ProfileFormSection
                        tag={InputSectionTag.TECHNICAL_INFO}
                        disabled={disabled}
                        formik={formik}
                    />
                )}

                <Box sx={{ mt: 2, mb: 1 }}>
                    <ProfileSectionTitle title="Billing Contact" />
                    <ProfileSectionCheck
                        label="Same as Owner"
                        checked={isOwnerBilling}
                        onChange={setBillingSameAsOwner}
                        disabled={disabled}
                    />
                </Box>

                {!isOwnerBilling && (
                    <ProfileFormSection
                        tag={InputSectionTag.BILLING_INFO}
                        disabled={disabled}
                        formik={formik}
                    />
                )}

                <ProfileFormSectionDomains
                    title="Nameserver Settings"
                    disabled={disabled}
                    formik={formik}
                />

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        mt: 2,
                    }}
                >
                    <ProfileSectionTitle title="Daily Statistics Report" />
                    <ProfileSectionCheck
                        label="Send Daily Statistics Report"
                        checked={getEmailReportsFlag()}
                        onChange={() => toggleEmailReportsFlag()}
                        disabled={disabled}
                    />
                </Box>

                {disabled ? (
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        sx={{ mb: 4 }}
                    >
                        <LoadingButton
                            size="medium"
                            variant="outlined"
                            type="button"
                            style={{
                                height: "40px",
                                margin: "0 20px",
                                borderColor: "red",
                                color: "red",
                            }}
                            onClick={() => {
                                setDeleteAlert(true);
                            }}
                        >
                            Clear Profile Settings
                        </LoadingButton>
                        <Link to={`/profile/${params.username}/edit`}>
                            <LoadingButton
                                size="medium"
                                variant="contained"
                                type="button"
                                style={{
                                    height: "40px",
                                }}
                            >
                                Edit
                            </LoadingButton>
                        </Link>
                    </Stack>
                ) : (
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        sx={{ mb: 4 }}
                    >
                        <LoadingButton
                            size="medium"
                            variant="contained"
                            type="submit"
                            style={{
                                height: "40px",
                            }}
                        >
                            Update
                        </LoadingButton>
                    </Stack>
                )}
            </Stack>
        </form>
    );
};

export default ProfileForm;
