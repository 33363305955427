import { getIn } from "formik";

/**
 * A custom hook for working with nested Formik fields, providing utility functions
 * to handle nested field names, values, errors, and validation state.
 *
 * @param formik - The Formik instance that manages the form's state.
 * @param fieldPathPrefix - An optional prefix for nested field paths.
 * @returns Utility functions for interacting with Formik fields.
 */
const useFormikField = (formik: any, fieldPathPrefix: string = "") => {
    // Returns the full field name, including the prefix if provided.
    const getFieldName = (field: string) => {
        if (fieldPathPrefix === "") return field;
        return `${fieldPathPrefix}.${field}`;
    };

    // Checks if a field is marked as "touched" in Formik.
    const isFieldTouched = (field: string): boolean =>
        getIn(formik.touched, getFieldName(field));

    // Gets the error message for a field from Formik's errors.
    const getFieldError = (field: string) =>
        getIn(formik.errors, getFieldName(field));

    // Gets the value of a field from Formik's values.
    const getFieldValue = (field: string) =>
        getIn(formik.values, getFieldName(field));

    // Checks if a field has errors and is touched.
    const fieldHasErrors = (fieldName: string) =>
        isFieldTouched(fieldName) && Boolean(getFieldError(fieldName));

    // Gets the error message for a touched field.
    const getValidationError = (fieldName: string) =>
        isFieldTouched(fieldName) && getFieldError(fieldName);

    return {
        getFieldName,
        isFieldTouched,
        getFieldError,
        getFieldValue,
        fieldHasErrors,
        getValidationError,
    };
};

export default useFormikField;
