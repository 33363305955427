import { Checkbox, Stack, Typography } from "@mui/material";

const ProfileSectionCheck = ({
    label,
    onChange,
    checked,
    disabled,
}: {
    label: string;
    onChange: (isSame: boolean) => void;
    checked: boolean;
    disabled: boolean;
}) => {
    return (
        <Stack direction="row" alignItems="center">
            <Typography
                sx={{
                    fontSize: 15,
                    color: "#3A3541CC",
                    width: "300px",
                }}
            >
                {label}
            </Typography>

            <Checkbox
                checked={checked}
                onChange={() => {
                    onChange(!checked);
                }}
                sx={{
                    transform: "scale(0.85)",
                }}
                disabled={disabled}
            />
        </Stack>
    );
};

export default ProfileSectionCheck;
