/* eslint-disable react/prop-types */
import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Box } from "@mui/material";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { InputSectionTag } from "../../types/profileSettingsTypes";
import { getValue, trimSpaces } from "../../utils/helpers";
import useFormikField from "../../hooks/useFormikField";

const styles = {
    formControlStyle: {
        my: 1,
        minWidth: 100,
    },
    inputGroup: {
        my: { md: 0, xs: 1 },
        ml: { md: 5, xs: 0 },
    },
};

const fields = [
    { label: "Name", name: "name" },
    { label: "Company", name: "company" },
    { label: "Street", name: "street" },
    { label: "City", name: "city" },
    { label: "State", name: "state" },
    { label: "Country", name: "country" },
    {
        label: "Zip",
        name: "zip",
    },
    {
        label: "Email",
        name: "email",
        transform: trimSpaces,
    },
];

export const ProfileSectionTitle = (props: { title: string }) => {
    return (
        <Typography
            sx={{
                fontSize: { sm: "18px", xs: "12px" },
                fontWeight: 600,
                color: "#3A3541CC",
                mr: 1,
                mb: 1,
            }}
        >
            {props.title}
        </Typography>
    );
};

const ProfileFormSection = ({
    disabled = false,
    tag,
    formik,
}: {
    disabled?: boolean;
    tag: InputSectionTag;
    formik: any;
}) => {
    const { getFieldName, getFieldValue, fieldHasErrors, getValidationError } =
        useFormikField(formik, tag);

    const renderFormField = ({
        label,
        name,
        type,
        disabled,
        onChange,
    }: {
        label: string;
        name: string;
        type?: string;
        disabled?: boolean;
        onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
    }) => {
        const hasError = fieldHasErrors(name);
        return (
            <Stack>
                <InputLabel>{label}</InputLabel>
                <FormControl sx={styles.formControlStyle}>
                    <TextField
                        fullWidth
                        name={getFieldName(name)}
                        type={type}
                        disabled={disabled}
                        value={getFieldValue(name)}
                        onChange={onChange}
                        error={hasError}
                    />
                    {hasError && (
                        <FormHelperText sx={{ mx: 0 }} error>
                            {getValidationError(name)}
                        </FormHelperText>
                    )}
                </FormControl>
            </Stack>
        );
    };

    const hasPhoneErrors = fieldHasErrors("phone");

    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Grid container spacing={2}>
                {fields.map((data, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        {renderFormField({
                            label: data.label,
                            name: data.name,
                            disabled,
                            onChange: (e) => {
                                let value = getValue(e.target.value);
                                if (data.transform) {
                                    value = data.transform(value);
                                }
                                formik.setFieldValue(
                                    getFieldName(data.name),
                                    value,
                                );
                            },
                        })}
                    </Grid>
                ))}
                <Grid item xs={12} sm={6} md={4}>
                    <Stack>
                        <InputLabel>Phone</InputLabel>
                        <PhoneInput
                            id={getFieldName("phone")}
                            value={getFieldValue("phone")}
                            numberInputProps={{
                                style: {
                                    padding: "11.5px",
                                    borderRadius: "4px",
                                    borderColor: hasPhoneErrors
                                        ? "#FF4D4F"
                                        : "#00000040",
                                    borderWidth: "1px",
                                    backgroundColor: "transparent",
                                    fontFamily: "Public Sans",
                                    color: "#3A3541CC",
                                    fontSize: "14px",
                                },
                                className: hasPhoneErrors
                                    ? "PhoneInputInput phoneInputError"
                                    : "PhoneInputInput",
                            }}
                            style={{ marginTop: "9px" }}
                            disabled={disabled}
                            onChange={(value) => {
                                formik.setFieldValue(
                                    getFieldName("phone"),
                                    value ?? "",
                                );
                            }}
                        />
                        {hasPhoneErrors && (
                            <FormHelperText sx={{ mx: 0 }} error>
                                {getValidationError("phone")}
                            </FormHelperText>
                        )}{" "}
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ProfileFormSection;
