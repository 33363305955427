export interface InputSectionType {
    name: string;
    company: string;
    street: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    phone: string;
    email: string;
}

export interface InputSectionTypeOptional {
    name: string;
    company: string;
    street: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    phone: string;
    email: string;
    sameAsOwner: boolean;
}

export interface NameserverInputSectionType {
    nameserver1: string;
    nameserver2: string;
}

export interface FormDataType {
    ownerInfo: InputSectionType;
    adminInfo: InputSectionTypeOptional;
    technicalContact: InputSectionTypeOptional;
    billingContact: InputSectionTypeOptional;
    nameserverSettings: NameserverInputSectionType;
    allowDailyEmailReports: boolean;
}

export interface FormDataTypeSubmit {
    ownerInfo: InputSectionType;
    adminInfo: InputSectionTypeOptional;
    technicalContact: InputSectionTypeOptional;
    billingContact: InputSectionTypeOptional;
    nameserverSettings: NameserverInputSectionType;
}

export enum InputSectionTag {
    OWNER_INFO = "ownerInfo",
    ADMIN_INFO = "adminInfo",
    TECHNICAL_INFO = "technicalInfo",
    BILLING_INFO = "billingInfo",
    NAMESERVERS = "nameservers",
}
