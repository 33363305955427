import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Box } from "@mui/material";
import { InputSectionTag } from "../../types/profileSettingsTypes";
import useFormikField from "../../hooks/useFormikField";
import { getValue, trimSpaces } from "../../utils/helpers";

const styles = {
    formControlStyle: {
        my: 1,
        minWidth: 100,
    },
    inputGroup: {
        my: { md: 0, xs: 1 },
        ml: { md: 5, xs: 0 },
    },
};

const ProfileFormSectionDomains = ({
    title,
    disabled = false,
    formik,
}: {
    title: string;
    disabled?: boolean;
    formik: any;
}) => {
    const { getFieldName, getFieldValue, fieldHasErrors, getValidationError } =
        useFormikField(formik, InputSectionTag.NAMESERVERS);
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                mt: 2,
            }}
        >
            <Typography
                sx={{
                    fontSize: { sm: "18px", xs: "12px" },
                    fontWeight: 600,
                    color: "#3A3541CC",
                    mr: 1,
                    mb: 2,
                }}
            >
                {title}
            </Typography>
            <Grid container spacing={2}>
                {["nameserver1", "nameserver2"].map((field, index) => {
                    const hasError = fieldHasErrors(field);
                    return (
                        <Grid item xs={12} sm={6} md={4} key={field}>
                            <Stack>
                                <InputLabel>{`Nameserver ${
                                    index + 1
                                }`}</InputLabel>
                                <FormControl sx={styles.formControlStyle}>
                                    <TextField
                                        fullWidth
                                        id={getFieldName(field)}
                                        name={getFieldName(field)}
                                        value={getFieldValue(field)}
                                        type="text"
                                        onChange={(e) => {
                                            const value = trimSpaces(
                                                getValue(e.target.value),
                                            );
                                            formik.setFieldValue(
                                                getFieldName(field),
                                                value,
                                            );
                                        }}
                                        disabled={disabled}
                                        error={hasError}
                                    />
                                    {hasError && (
                                        <FormHelperText sx={{ mx: 0 }} error>
                                            {getValidationError(field)}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Stack>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};

export default ProfileFormSectionDomains;
