import { isValidPhoneNumber } from "react-phone-number-input";
import * as yup from "yup";
import { InputSectionTag } from "../types/profileSettingsTypes";

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const zipRegex = /^[A-Z0-9]{5,7}$/;
export const phoneRegex = /^\+?\d{1,3}-?\d{3}-?\d{3}-?\d{4}$/;
export const nameserverRegex =
    /^([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+\.[a-zA-Z]{2,}$/;

export const infoValidationSchema = () =>
    yup.object().shape({
        name: yup.string().required("Required Field"),
        company: yup.string(),
        street: yup.string().required("Required Field"),
        city: yup.string().required("Required Field"),
        state: yup.string().required("Required Field"),
        country: yup.string().required("Required Field"),
        zip: yup.string().required("Required Field"),
        phone: yup
            .string()
            .required("Required Field")
            .test(
                "isValidPhoneNumber",
                "Invalid phone number",
                (value: string) => isValidPhoneNumber(value),
            ),
        email: yup
            .string()
            .required("Required Field")
            .matches(emailRegex, "Invalid email"),
    });

const infoSchema = () =>
    yup.object().shape({
        name: yup.string(),
        company: yup.string(),
        street: yup.string(),
        city: yup.string(),
        state: yup.string(),
        country: yup.string(),
        zip: yup.string(),
        phone: yup.string(),
        email: yup.string(),
    });

const domainsValidationSchema = () =>
    yup.object().shape({
        nameserver1: yup
            .string()
            .required("Required Field")
            .matches(nameserverRegex, "Invalid nameserver"),
        nameserver2: yup
            .string()
            .required("Required Field")
            .matches(nameserverRegex, "Invalid nameserver")
            .test("not-duplicate", "Duplicate Nameserver", function (value) {
                return value !== this.parent.nameserver1; // Compare with nameserver1
            }),
    });

export const generateSchema = (params: {
    adminSameAsOwner: boolean;
    technicalSameAsOwner: boolean;
    billingSameAsOwner: boolean;
}) => {
    const { adminSameAsOwner, technicalSameAsOwner, billingSameAsOwner } =
        params;

    return yup.object().shape({
        adminSameAsOwner: yup.bool(),
        technicalSameAsOwner: yup.bool(),
        billingSameAsOwner: yup.bool(),
        ownerInfo: infoValidationSchema(),
        adminInfo: adminSameAsOwner ? infoSchema() : infoValidationSchema(),
        technicalInfo: technicalSameAsOwner
            ? infoSchema()
            : infoValidationSchema(),
        billingInfo: billingSameAsOwner ? infoSchema() : infoValidationSchema(),
        nameservers: domainsValidationSchema(),
        allowDailyEmailReports: yup.bool(),
    });
};

const infoInputs = {
    name: "",
    company: "",
    street: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    phone: "",
    email: "",
};

export const defaultValues = {
    adminSameAsOwner: true,
    technicalSameAsOwner: true,
    billingSameAsOwner: true,
    [InputSectionTag.OWNER_INFO]: { ...infoInputs },
    [InputSectionTag.ADMIN_INFO]: { ...infoInputs },
    [InputSectionTag.TECHNICAL_INFO]: { ...infoInputs },
    [InputSectionTag.BILLING_INFO]: { ...infoInputs },
    [InputSectionTag.NAMESERVERS]: {
        nameserver1: "",
        nameserver2: "",
    },
    allowDailyEmailReports: true,
};

export type FormValuesType = typeof defaultValues;
